import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import dashboard from '../assets/images/dashboard.png';
import logout from '../assets/images/logout.png';
import StarMids from '../assets/images/starmids.png';
import TrustbucksLogo from '../assets/images/trustbucks/trustbucksLogo.png';
import FxmbLogo from '../assets/images/fxmb/fxmb-logo.png';
import TlaPayLogo from '../assets/images/tlapay/TlapayLogo.png';
import T365Logo from '../assets/images/t365/t365-logo.png';
import PacificCapitalLogo from '../assets/images/pacificCapital/PacificCapitalLogo.png';
import { whiteLabel } from '../config';
import { menuList } from '../utils/menuList';
import '../assets/css/t365/apiData.scss';
import '../assets/css/trustbucks/apiData.scss';

import { loginActions } from './../services/loginActions';

import { Column, Row } from 'simple-flexbox';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withTranslation } from 'react-i18next';

import * as actions from '../actions';
import { merchant } from "../config";

import { FETCH_PERMISSIONS } from '../actions/types';
const store =  require('../reducers/index');

class Header extends Component {
  state = {
    userLoggedIn: false,
    isBurgerOpen: false,
    menu: menuList,
    access: []
  };

  node = null;
  burgerNode = null;
  subscribeFunction = null;

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
      this.setState({ userLoggedIn: true });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });
  }

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  checkPageAccessCategory = (categoryName) => {
    const { access } = this.state;
    const freeAccessed = ["MY_ACCOUNT", "API"];
    if (freeAccessed.some(elem => elem === categoryName)) {
      return true;
    }

    const foundPermissions = access.filter(elem => elem.category === categoryName);
    return foundPermissions.some(elem => elem.state);
  };

  handleClick = (e) => {
    if ((this.node && this.node.contains(e.target)) || (this.burgerNode && this.burgerNode.contains(e.target))) {
      return;
    }

    this.handleClickOutside();
  };

  handleClickOutside = () => {
    this.setState({
      isBurgerOpen: false
    });
  };

  logoutHandler = () => {
    loginActions.logout();
    window.location.href = '/login';
  };

  changeLanguage = (language) => {
    const { i18n } = this.props;
    localStorage.language = language;
    i18n.changeLanguage(language);
  };

  onBurgerClick = () => {
    this.setState({
      isBurgerOpen: !this.state.isBurgerOpen
    });
  };

  onNavClick = (link) => {
    this.setState({
      isBurgerOpen: false
    });

    if (link === "Log out") {
      this.logoutHandler();
    }
  };

  displayMenuItem = (menu) => {
    const { access } = this.state;
    if (
      menu.submenu.find((elem) => elem.access === "MY_ACCOUNT") ||
      menu.submenu.find((elem) => elem.access === "API")
    ) {
      return true;
    }

    const foundItems = access.filter((permission) =>
      menu.submenu.some(
        (elem) =>
          permission[elem.permission ? "permission" : "category"] ===
          elem.access
      )
    );
    return foundItems.some((elem) => elem.state);
  };

  sortMenu = (menu) => {
    menu.sort((elemA, elemB) => {
      if (elemA.name < elemB.name)
        return -1;
      if (elemA.name > elemB.name)
        return 1;
      return 0;
    });

    return menu;
  };

  render() {
    const { isBurgerOpen, userLoggedIn, menu } = this.state;
    const { i18n } = this.props;
    const defaultLanguage = i18n.language;
    if (!localStorage.language) {
      localStorage.language = defaultLanguage;
    }

    const logo = (
      <NavLink to='/dashboard' onClick={ () => this.onNavClick() } className={ `header-logo ${whiteLabel}` }>
        {whiteLabel === 'trustbucks' ? (
          <img src={ TrustbucksLogo } alt="TRUSTBUCKS AGENTS" style={ { width: '180px', margin: '0 3px 0 10px' } } />
        ) : whiteLabel === 'fxmb' ? (
          <img src={ FxmbLogo } alt="FXMB" style={ { width: '110px', margin: '0 3px 0 50px' } } />
        ) : whiteLabel === 'tlapay' ? (
          <img src={ TlaPayLogo } alt="TlaPay" style={ { width: '110px', margin: '0 3px 0 50px' } } />
        ) : whiteLabel === 't365' ? (
          <img src={ T365Logo } alt="Transact 365" style={ { width: '250px', margin: '0 3px 0 20px' } } />
        ) : whiteLabel === 'pacificCapital' ? (
          <img src={ PacificCapitalLogo } alt="Pacific Capital Group" style={ { width: '300px', margin: '0 3px 0 20px' } } />
        ) : (
          <>
            <span className="logo-text"> {merchant} BACK OFFICE</span>
            <br/>
            <span className="powered-by"> POWERED BY STAR <img src={ StarMids } className="starmids-logo" alt="StarMids"/> MIDS </span>
          </>
        )}
      </NavLink>
    );

    return (
      <Row horizontal='spaced' vertical='center' flexGrow={ 1 } className={ `${whiteLabel} navBar navbar` }>
        <Row horizontal={ userLoggedIn ? `start` : `center` } vertical='center' className="navBar-inner">
          { logo }
        </Row>

        {userLoggedIn && (
          <div
            ref={ node => this.burgerNode = node }
            className={ "burger-container " + (isBurgerOpen ? "change" : "") }
            onClick={ this.onBurgerClick }
          >
            <div className="burger-bar1"></div>
            <div className="burger-bar2"></div>
            <div className="burger-bar3"></div>
          </div>
        )}

        {<div ref={ node => this.node = node } className={ `burger-content ${isBurgerOpen ? 'open' : ''}` }>
          <div className="logo">
            { logo }
          </div>
          <div className="menu">
            {menu.map((menuItem, i) => {
              return (
                this.displayMenuItem(menuItem) && (
                  <Column key={ i } className="menublock">
                    <Column className="header">{menuItem.header}</Column>
                    {this.sortMenu(menuItem.submenu).map((item, j) => {
                      return (
                        (item.permission
                          ? this.checkPageAccess(item.access)
                          : this.checkPageAccessCategory(item.access)) && (
                          <NavLink
                            key={ j }
                            to={ item.url }
                            className="menuItem"
                            onClick={ () => this.onNavClick() }
                          >
                            {` ${item.name} `}
                          </NavLink>
                        )
                      );
                    })}
                  </Column>
                )
              );
            })}
            <div className="logout-item">
              <NavLink
                to="/login"
                className="menuItem"
                onClick={ () => this.onNavClick("Log out") }
              >
                Log out
              </NavLink>
            </div>
          </div>
        </div>
        }

        { userLoggedIn && (
          <Row>
            <Row horizontal='end' vertical='center' className="group-icons">
              <Row horizontal='center' vertical='center' >
                { this.checkPageAccessCategory("DASHBOARD") && <NavLink to='/dashboard' >
                  <Column horizontal='center' vertical='center' className="icon">
                    <img src={ dashboard } className="" height="25" alt="" />
                    <div style={ {fontSize: 14} }> Dashboard </div>
                  </Column>
                </NavLink> }
              </Row>
              <Row horizontal='center' vertical='center' >
                <NavLink to='/login' onClick={ this.logoutHandler } >
                  <Column horizontal='center' vertical='center' className="icon">
                    <img src={ logout } className="" height="25" alt="" />
                    <div style={ {fontSize: 14} }> Log out </div>
                  </Column>
                </NavLink>
              </Row>
            </Row>
          </Row>
        )}
        {this.props.message && this.props.message.show && (
          <div style={ {position: 'fixed', width: '100%', height:'100px'} }>
            <Row horizontal='center' vertical='center'>
              <div className="popup-window">{this.props.message.message}</div>
            </Row>
          </div>
        )}
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.message,
  };
}

export default compose(
  withTranslation("common"),
  connect(mapStateToProps, actions)
)(Header);
