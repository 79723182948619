import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";
import Datetime from 'react-datetime';
import Swal from 'sweetalert2';

import Calendar from '../../../assets/images/calendar.png';
import RefundsIcon from '../../../assets/images/report.png';
import RefundsIconTrustbucks from '../../../assets/images/trustbucks/report.png';
import RefundsIconT365 from '../../../assets/images/t365/report.png';
import RefundsIconPacificCapital from '../../../assets/images/pacificCapital/report.png';

import Alert from "../Alert";
import ComponentHeader from "../../componentHeader";
import Dropdowns from '../apiData/dropdowns';
import Form from "../../../core/Form";
import Multiselect from "../generateReport/customMultiselect";
import Select from "../Select";
import Spinner from "../../Spinner";
import Table from "../Table";

import '../../../assets/css/pacificCapital/generateReports.scss';
import '../../../assets/css/t365/generateReports.scss';
import '../../../assets/css/trustbucks/generateReports.scss';
import '../../../assets/css/generateReports.css';
import '../../../assets/css/refunds.css';

import { whiteLabel } from '../../../config';
import { getColor, getFormattedDate } from "../../../utils/utils.js";
import { crudActions } from "../../../services/crudActions";
import { FETCH_PERMISSIONS } from '../../../actions/types';
const store =  require('../../../reducers/index');

class Refunds extends Component {
  state = {
    controls: {
      timePeriod: {
        value:'thisMonth'
      },
      from: this.getPeriod(1).from,
      to: this.getPeriod(1).to
    },
    dateFieldDisabled: true,
    dropdowns: {
      periods: Dropdowns.periods
    },
    timeStamp: {
      fromDateTimeStamp: this.getPeriod(2).from,
      toDateTimeStamp: this.getPeriod(2).to
    },
    lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY'),
    paymentMethods: [],
    currencies: [],
    searchTransactionsForm: new Form({
      depositMethod: [],
      currency: [],
      userName: "",
      transactionId: ""
    }, [{
      name: "depositMethod",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "currency",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "userName",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "transactionId",
      type: "isString",
      rules: {
        required: false
      }
    }]),

    pageNumber: 1,
    perPage: 20,
    transactionsCount: 0,

    searchClicked: false,
    showResult: false,

    showAlert: false,
    alertType: "success",
    alertMessage: ""
  };

  subscribeFunction = null;

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    this.loadLookupsData();
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  loadLookupsData() {
    crudActions.get(`v1/clients/current`).then(
      data => {
        if (data) {
          crudActions.get(`v1/reports/company/${data.companyId}/lookups`).then(
            (lookups) => {
              if (lookups) {
                this.setState({
                  paymentMethods: lookups.depositMethod,
                  currencies: lookups.currency
                });
              }
            }
          );
        }
      }
    );
  }

  isDateValid = (current, state) => {
    const { timeStamp, fromDate = timeStamp.fromDateTimeStamp, toDate = timeStamp.toDateTimeStamp } = this.state;
    const yesterday = Datetime.moment().utc().subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return current.isBefore(!fromDate ? yesterday : toDate);
    }

    return current.isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  getPeriod(periodType) {
    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().subtract(1, 'day'),
        to: moment().utc().subtract(1, 'day')
      };
    case 2: // this month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month()).date(1),
        to: moment().utc()
      };
    case 3: // last month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1).date(1),
        to: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1)
          .date(moment().utc().month(moment().utc().month() - 1).daysInMonth())
      };
    case 4: // this year
      return {
        from: moment().utc().year(moment().utc().year()).month(0).date(1),
        to: moment().utc()
      };
    case 5: // last year
      return {
        from: moment().utc().year(moment().utc().year() - 1).month(0).date(1),
        to: moment().utc().year(moment().utc().year() - 1).month(11).date(31)
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc(),
        to: moment().utc()
      };
    }
  };

  onSelectPeriodChange = (value, id) => {
    const timeStamp = {...this.state.timeStamp};
    const controls = {...this.state.controls};
    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    const dateFieldDisabled = value.id <= 5; // depends on any predefined period (today, yesterday etc.)
    const period = this.getPeriod(value.id);

    controls[id] = value;
    timeStamp.fromDateTimeStamp = period.from;
    timeStamp.toDateTimeStamp = period.to;
    controls.from = getFormattedDate(timeStamp.fromDateTimeStamp._d);
    controls.to = getFormattedDate(timeStamp.toDateTimeStamp._d);

    //if this month
    if (value.id === 2) {
      this.thisMonth = true;
    } else {
      this.thisMonth = false;
    }

    this.setState({
      controls: controls,
      dateFieldDisabled: dateFieldDisabled,
      timeStamp: timeStamp
    });
  };

  handleDateChange = (event, state, fieldState) => {
    const controls = {...this.state.controls};
    const timeStamp = {...this.state.timeStamp};

    timeStamp[state] = event;

    const day = event._d.getDate() > 9 ? event._d.getDate() : '0' + event._d.getDate();
    const month = event._d.getMonth() + 1 > 9 ? event._d.getMonth() + 1 : '0' + (event._d.getMonth() + 1);
    controls[fieldState] = `${day}.${month}.${event._d.getFullYear()}`;

    this.setState({controls: controls, timeStamp: timeStamp, isSubmitting: false});
  };

  onValueChange = (event, field) => {
    const { searchTransactionsForm, searchClicked } = this.state;
    if (!event) {
      searchTransactionsForm[field] = event;
    } else if (event.target) {
      searchTransactionsForm[field] = event.target.value;
    } else if (event.value) {
      searchTransactionsForm[field] = event.value;
    } else {
      searchTransactionsForm[field] = event.map(elem => elem.value);
    }

    if (searchClicked) {
      searchTransactionsForm.isFormValid();
    }

    this.setState({
      searchTransactionsForm
    });
  };

  onSearchTransactions = () => {
    const { searchTransactionsForm, timeStamp, pageNumber } = this.state;
    const isValid = searchTransactionsForm.isFormValid();
    this.setState({
      searchTransactionsForm,
      showResult: isValid,
      isLoading: isValid
    });

    if (isValid) {
      const data = searchTransactionsForm.data();
      const fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().format("DD.MM.YYYY");
      const toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().format("DD.MM.YYYY");

      crudActions.post(`v1/refund`, Object.assign(data, {
        fromDate: fromDateTimeStamp,
        toDate: toDateTimeStamp
      }), { Page: pageNumber }).then(
        (transactions) => {
          if (transactions) {
            this.setState({
              transactions,
              isLoading: false
            });
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLoading: false,
              showResult: false
            });
          }
        }
      );
    }
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    }, this.state.showAlert && this.state.alertType === "success" ? this.onSearchTransactions : null);
  };

  getColumns = () => {
    const { access } = this.state;

    const columns = [{
      value: "date",
      label: "Date",
      leftAligned: true
    }, {
      value: "username",
      label: "Username",
      centerAligned: true
    }, {
      value: "amount",
      label: "Amount",
      rightAligned: true
    }, {
      value: "currency",
      label: "Currency",
      centerAligned: true
    }, {
      value: "paymentMethod",
      label: "Payment Method",
      centerAligned: true
    }, {
      value: "orderId",
      label: "Order ID",
      rightAligned: true
    }, {
      value: "transactionId",
      label: "Transaction ID",
      rightAligned: true
    }];

    const refundEditPermission = access.find(elem => elem.permission === "REFUND_EDIT");

    if (refundEditPermission && refundEditPermission.state) {
      columns.push({
        value: "id",
        label: "Refund",
        centerAligned: true,
        className: "btn edit-refund",
        isButton: true,
        triggerEvent: (referral) => this.onRefund(referral)
      });
    }

    return columns;
  };

  onPageChange = (pageNumber) => {
    if (pageNumber === 0) {
      return false;
    }

    this.setState({
      pageNumber
    }, this.onSearchTransactions);
  };

  showCurrentAmount = () => {
    const { transactionsCount, pageNumber, perPage } = this.state;
    if (transactionsCount === 0) {
      return '0';
    }
    const upper = Math.min(transactionsCount, pageNumber * perPage);
    const lower = Math.max(pageNumber - 1, 0) * perPage + 1;
    return lower + ' - ' + upper;
  };

  onRefund = (transaction) => {
    Swal.fire({
      title: 'Warning',
      text: 'Do you really want to refund this transaction?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        crudActions.put(`v1/refund/${transaction.transactionId}`).then(
          () => {
            this.setState({
              showAlert: true,
              alertType: "success",
              alertMessage: "Refund successfully processed."
            });
          }
        ).catch(
          err => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message
              });
            }
          }
        );
      }
    });
  };

  mapSelectedItems = (propertyName, lookupsName) => {
    const field = this.state.searchTransactionsForm[propertyName];
    const lookup = this.state[lookupsName];

    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  getIcon = () => {
    switch (whiteLabel) {
    case "t365":
      return RefundsIconT365;
    case "pacificCapital":
      return RefundsIconPacificCapital;
    case "trustbucks":
      return RefundsIconTrustbucks;
    default:
      return RefundsIcon;
    }
  };

  render() {
    const { controls, searchTransactionsForm, paymentMethods, currencies, isLoading, showResult,
      alertMessage, showAlert, alertType, transactions } = this.state;
    return (
      <Row flexGrow={ 1 } className={ `${whiteLabel} module generateReports refunds` } vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title="Refunds"
            lastUpdate={ this.state.lastUpdate }
            img={ this.getIcon() } />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    SEARCH TRANSACTIONS
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Payment Method </label>
                          <Multiselect
                            isError={ searchTransactionsForm.errors.has("depositMethod") }
                            selectedItems={ this.mapSelectedItems("depositMethod", "paymentMethods") }
                            items={ paymentMethods }
                            type={ "depositMethod" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                      </Row>
                    </Row>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Currency </label>
                          <Multiselect
                            isError={ searchTransactionsForm.errors.has("currency") }
                            selectedItems={ this.mapSelectedItems("currency", "currencies") }
                            items={ currencies }
                            type={ "currency" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Time Period </label>
                          <Select id="timePeriod"
                            name="timePeriod"
                            value={ controls.timePeriod.value || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                            options={ this.state.dropdowns.periods }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label>From Date</label>
                          <img src={ Calendar } className="calendar-svg" alt=""/>
                          <Datetime type="text"
                            timeFormat={ false }
                            className="datetime-container"
                            dateFormat="DD.MM.YYYY"
                            inputProps={ {
                              disabled: this.state.dateFieldDisabled,
                              readOnly: true//user is not allowed changed the date from input
                            } }
                            utc={ true }
                            isValidDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                            value={ this.state.timeStamp.fromDateTimeStamp || '' }
                            onChange={ (event) => this.handleDateChange(event, 'fromDateTimeStamp', 'from') }
                            required={ true }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label>To Date</label>
                          <img src={ Calendar } className="calendar-svg" alt=""/>
                          <Datetime type="text"
                            className="datetime-container"
                            timeFormat={ false }
                            dateFormat="DD.MM.YYYY"
                            inputProps={ {
                              disabled: this.state.dateFieldDisabled,
                              readOnly: true//user is not allowed changed the date from input
                            } }
                            utc={ true }
                            isValidDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                            value={ this.state.timeStamp.toDateTimeStamp || '' }
                            onChange={ (event) => this.handleDateChange(event, 'toDateTimeStamp', 'to') }
                            required={ true }/>
                        </Column>
                      </Row>
                    </Row>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Search Username </label>
                          <input
                            className={ "form-control " + (searchTransactionsForm.errors.has("userName") ? "error-field" : "") }
                            value={ searchTransactionsForm.userName || '' }
                            onChange={ (value) => this.onValueChange(value, 'userName') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Search Transaction ID </label>
                          <input
                            className={ "form-control " + (searchTransactionsForm.errors.has("transactionId") ? "error-field" : "") }
                            value={ searchTransactionsForm.transactionId || '' }
                            onChange={ (value) => this.onValueChange(value, 'transactionId') }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                      </Row>
                    </Row>
                    <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <Button
                            style={ { width: "100%", outline: isLoading ? "5px auto -webkit-focus-ring-color" : "0" } }
                            type="submit"
                            className="btn defaultBtn"
                            onClick={ () => this.onSearchTransactions() }>
                            SEARCH
                          </Button>
                        </Column>
                      </Row>
                    </Row>
                  </div>
                </Panel.Body>
              </Panel>

              { showResult && <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    SEARCH TRANSACTIONS
                  </Panel.Title>
                  { /*<span className="transactions-pagination-count">
                    SHOW { this.showCurrentAmount() } OF { transactionsCount } TRANSACTIONS
                  </span>*/}
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                    <Spinner smallContainer={ true } />
                  </div> : <div className="panel-content table-content">
                    <Table
                      columns={ this.getColumns() }
                      data={ transactions }
                      headerColor={ getColor(whiteLabel) }
                      headerTextColor={ "#FFFFFF" }
                      isStriped={ true }/>
                    { /*<Row flexGrow={1} wrap={true} horizontal='end' vertical='end'>
                        <Row flexGrow={1} wrap={true} horizontal='end'>
                          <Button
                            style={{ outline: isLoading ? "5px auto -webkit-focus-ring-color" : "0" }}
                            className="btn pagination-button"
                            disabled={pageNumber === 1}
                            onClick={() => this.onPageChange(pageNumber - 1)}>
                            Previous
                          </Button>
                          <Button
                            style={{ outline: isLoading ? "5px auto -webkit-focus-ring-color" : "0" }}
                            className="btn pagination-button"
                            disabled={pageNumber === Math.floor(transactionsCount / perPage) ||
                                      Math.floor(transactionsCount / perPage) === 0}
                            onClick={() => this.onPageChange(pageNumber + 1)}>
                            Next
                          </Button>
                        </Row>
                      </Row> */}
                  </div> }
                </Panel.Body>
              </Panel> }
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor={ alertType === "success" ? getColor(whiteLabel) : "#DD6B55" }
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default Refunds;
